<template>
  <div>
    <b-card>
      <b-row class="mb-3">
        <!-- Filtering Feature -->
        <b-col cols="8" lg="3">
          <b-form-input
            v-model="filter"
            type="search"
            placeholder="Type to Search"
            size="sm"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-table
        :items="permits"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        responsive
        stacked="md"
        tbody-tr-class="cardOnMobile"
      >
        <template #cell(fullName)="data">
          {{
            `${data.item.userFirstName} ${data.item.userMiddleInitial} ${data.item.userLastName}`
          }}
        </template>

        <template #cell(actions)="data">
          <button class="btn p-0" @click="toDetail(data.item.id)">
            <i class="bi-arrow-right text-info"></i>
          </button>
        </template>
      </b-table>
      <b-row class="align-items-center">
        <!-- Per page select -->
        <b-col cols="5">
          <p class="d-inline-block mb-0">Show</p>
          <b-form-select
            class="d-inline-block mx-1"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
            style="width: 65px"
          ></b-form-select>
          <p class="d-inline-block mb-0">entries</p>
        </b-col>
        <b-col cols="7">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
  export default {
    name: 'PermitListAdmin',

    props: {
      permits: {
        type: Array,
        required: true
      }
    },

    data () {
      return {
        fields: [
          { key: 'fullName', label: 'Taxpayer', sortable: true },
          { key: 'businessName', label: 'Business', sortable: true },
          { key: 'type', sortable: true },
          { key: 'status', sortable: true },
          { key: 'actions', label: '', sortable: false }
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: []
      }
    },

    methods: {
      renderIsActive (bool) {
        return bool ? 'Active' : 'Inactive'
      },

      renderDate (dateString) {
        return dateString.substr(0, 10)
      },

      toDetail (id) {
        this.$router.push({ name: 'PermitEdit', params: { id } })
        console.log(id)
      }
    }
  }
</script>

<style>
</style>

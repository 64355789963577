import api from './setup'

export default {

  async findAll () {
    const { data } = await api.get('/permits')
    return data
  },

  async findById (id) {
    const { data } = await api.get(`/permits/${id}`)
    return data
  },

  async findByBusinessId (id) {
    const { data } = await api.get(`/permits/users/${id}`)
    return data
  },

  async save (permitData) {
    // If the permitData has an id then run update busines
    if (permitData.id) {
      const { data } = await api.put(`/permits/${permitData.id}`, permitData)
      return data
    }

    // Run create busines
    const { data } = await api.post('/permits', permitData)
    return data
  },

  async saveDocuments (permitId, documents) {
    const { data } = await api.post(`/permits/${permitId}/documents`, documents,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    return data
  },

  async uploadReceipt (permitId, receipt) {
    const { data } = await api.post(`/permits/${permitId}/receipt`, receipt,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    return data
  },

  async uploadAssessment (permitId, assessment) {
    const { data } = await api.post(`/permits/${permitId}/assessment`, assessment,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    return data
  },

  async uploadMayorPermit (permitId, mayorPermit) {
    const { data } = await api.post(`/permits/${permitId}/mayor-permit`, mayorPermit,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    return data
  },

  async delete (id) {
    return api.delete(`/permits/${id}`)
  }

}

<template>
  <b-container>
    <b-row>
      <b-col>
        <h4 class="page-title">Permit List</h4>

        <!-- List view for taxpayer -->
        <permit-list v-if="!isAdmin" :permits="permits"></permit-list>

        <!-- List view for admin -->
        <permit-list-admin
          v-if="isAdmin"
          :permits="permits"
        ></permit-list-admin>
      </b-col>
    </b-row>
    <app-spinner ref="spinner"></app-spinner>
    <app-toast ref="toast"></app-toast>
  </b-container>
</template>

<script>
  import PermitService from '@/services/PermitService'
  import PermitList from '@/components/PermitList.vue'
  import PermitListAdmin from '@/components/PermitListAdmin.vue'

  export default {
    name: 'PermitIndex',

    components: { PermitList, PermitListAdmin },

    data () {
      return {
        permits: []
      }
    },

    computed: {
      isAdmin () {
        return this.$store.state.user.role === 'admin'
      }
    },

    methods: {
      async loadPermits () {
        this.$refs.spinner.show()

        try {
          this.permits = await PermitService.findAll()
          this.$refs.spinner.hide()
        } catch (error) {
          this.$refs.toast.error(error.response.data.message)
        }
      }
    },

    mounted () {
      this.loadPermits()
    }
  }
</script>

<style>
</style>
